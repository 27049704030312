<template>
  <div class="csn-platinclub">
    <div class="csn-platinclub-container-1"></div>
    <div class="csn-platinclub-benefits">
      <div class="csn-platinclub-title">{{ t('platinclub_benefits') }}</div>
      <div class="csn-platinclub-subtitle">
        {{ t('our_exclusive_loyalty') }}
      </div>
      <div class="csn-platinclub-text">
        {{ t('join_the_platinclub') }}
      </div>
      <div class="csn-platinclub-benefits-card-container">
        <div class="csn-platinclub-benefits-card">
          <div class="csn-platinclub-benefits-card-image"></div>
          <div class="csn-platinclub-benefits-card-title">
            {{ t('vip_experiences') }}
          </div>
          <div class="csn-platinclub-benefits-card-text">
            {{ t('our_platinclub_team') }}
          </div>
        </div>
        <div class="csn-platinclub-benefits-card">
          <div class="csn-platinclub-benefits-card-image"></div>
          <div class="csn-platinclub-benefits-card-title">
            {{ t('platinclub_support') }}
          </div>
          <div class="csn-platinclub-benefits-card-text">
            {{ t('our_support_team') }}
          </div>
        </div>
        <div class="csn-platinclub-benefits-card">
          <div class="csn-platinclub-benefits-card-image"></div>
          <div class="csn-platinclub-benefits-card-title">
            {{ t('collect_and_redeem') }}
          </div>
          <div class="csn-platinclub-benefits-card-text">
            {{ t('you_play_we_pay') }}
          </div>
        </div>
      </div>
    </div>
    <div class="csn-platinclub-status">
      <div class="csn-platinclub-title">{{ t('platinclub_status') }}</div>
      <div class="csn-platinclub-subtitle">
        {{ t('welcome_to_the_platin_club') }}
      </div>
      <div class="csn-platinclub-text">
        {{ t('from_your_very_first_deposit') }}
      </div>
      <div v-if="isPending"><Loader /></div>
      <div v-if="cardList" class="csn-platinclub-card-list">
        <div
          v-for="(card, index) in cardList"
          :key="index"
          class="csn-platinclub-card-container"
        >
          <span class="csn-platinclub-card-name">{{ card.name }}</span>
          <span class="csn-platinclub-card-description">{{ card.title }}</span>
          <ClubCard :level="card.name" />
          <div class="csn-platinclub-card-status">
            <div
              v-if="cardInfo.cardLevel > index"
              class="csn-platinclub-card-status-reached"
            >
              {{ t('reached') }}
            </div>
            <div
              v-if="cardInfo.cardLevel === index"
              class="csn-platinclub-card-status-current"
            >
              <BalloonArrow />
              <span>{{ t('your_status') }}</span>
            </div>
            <div
              v-if="cardInfo.cardLevel < index"
              class="btn casino-btn csn-platinclub-card-status-reach"
            >
              {{ t('reach_status_now') }}
            </div>
          </div>
        </div>
      </div>

      <div class="csn-platinclub-card-footer">
        {{ t('the_platin_status_will_be_granted') }}
      </div>
    </div>
    <div class="csn-platinclub-your-benefits">
      <div class="csn-platinclub-title">
        {{ t('your_benefits_at_a_glance') }}
      </div>
      <div class="csn-platinclub-subtitle">
        {{ t('whats_in_it') }}
      </div>

      <div
        v-if="rendersBenefitTable"
        class="csn-platinclub-benefit-table-container"
      >
        <table class="csn-platinclub-benefit-table">
          <tr
            v-for="(tableKey, rowIndex) in benefitTableDictionary"
            :key="rowIndex"
            class="csn-platinclub-benefit-tr"
          >
            <th :class="getLeftThClass(rowIndex)">
              {{ t([tableKey]) }}
            </th>
            <th
              v-for="(tableData, columnIndex) in benefitTableData"
              :key="columnIndex"
              :class="getThClass(rowIndex, columnIndex)"
            >
              <ClubStatusIcon
                v-if="isStatusRaw(rowIndex)"
                :level="columnIndex"
                class="csn-platinclub-status-icon"
              />
              <span v-else-if="isBetAmountRaw(rowIndex)">
                {{ format(tableData[tableKey]) }}
              </span>
              <div
                v-else-if="isAutomatedGiftRaw(rowIndex)"
                class="csn-platinclub-benefit-row-4"
              >
                <span v-if="tableData[tableKey].text">
                  {{ tableData[tableKey].text }}
                </span>
                <div
                  v-else-if="tableData[tableKey].state"
                  class="csn-platinclub-radio csn-platinclub-radio-on"
                >
                  <span></span>
                </div>
                <div
                  v-else
                  class="csn-platinclub-radio csn-platinclub-radio-off"
                ></div>
              </div>

              <div
                v-else-if="isRadioRaw(rowIndex)"
                class="csn-platinclub-benefit-row-4"
              >
                <div
                  v-if="tableData[tableKey]"
                  class="csn-platinclub-radio csn-platinclub-radio-on"
                >
                  <span></span>
                </div>
                <div
                  v-else
                  class="csn-platinclub-radio csn-platinclub-radio-off"
                ></div>
              </div>

              <span
                v-else
                :class="`csn-platinclub-benefit-th-span-${rowIndex}`"
              >
                {{ tableData[tableKey] }}
              </span>
            </th>
          </tr>
        </table>
      </div>
      <div v-else><Loader /></div>

      <div class="csn-platinclub-black-container">
        <ClubStatusIcon
          class="csn-platinclub-status-icon csn-platinclub-status-icon-black"
        />
        <div class="csn-platinclub-black-left-container">
          <div class="csn-platinclub-black-title">{{ t('black') }}</div>
          <span class="csn-platinclub-black-subtitle">
            {{ t('vip_platin_club') }}
          </span>
        </div>
        <div class="csn-platinclub-black-right-container">
          <EmailIcon class="csn-platinclub-email-icon" />
          <span class="csn-platinclub-black-subtitle">
            {{ t('invite_only') }}
          </span>
        </div>
      </div>

      <div class="csn-platinclub-benefits-footer">
        <span>{{ t('for_a_minimum_deposit') }}</span>
        <span>{{ t('cashback_is_granted') }}</span>
        <span>{{ t('preferred_wihdrawal_only') }}</span>
      </div>
    </div>
    <div class="csn-platinclub-exclusive">
      <div class="csn-platinclub-title">
        {{ t('exclusive_and_personal_offers') }}
      </div>
      <div class="csn-platinclub-subtitle">
        {{ t('enable_to_stay_updated') }}
      </div>
      <span class="csn-platinclub-exclusive-text">{{ t('make_sure') }}</span>
      <button class="btn casino-btn csn-platinclub-exclusive-btn">
        {{ t('check_my_setting') }}
      </button>
    </div>
    <div class="csn-platinclub-conditions">
      <div class="csn-platinclub-conditions-container">
        <div class="csn-platinclub-conditions-title">
          {{ t('conditions_platinclub') }}
        </div>
        <div class="csn-platinclub-conditions-subtitle">
          {{ t('loyalty_program') }}
        </div>

        <ul class="csn-platinclub-conditions-list">
          <li v-for="item in 11" :key="item">
            {{ t(`general_condition_${item}`) }}
          </li>
        </ul>

        <div class="csn-platinclub-conditions-title">
          {{ t('platincoins_conditions') }}
        </div>
        <div class="csn-platinclub-conditions-subtitle">
          {{ t('every_real_money') }}
        </div>

        <ul class="csn-platinclub-conditions-list">
          <li v-for="item in 7" :key="item">
            {{ t(`platincoins_condition_${item}`) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { VipApi } from '@/api'
import {
  Module,
  CASINO_INFO,
  PLATINCLUB,
  ResponseState,
  Digit,
  EMPTY_ARRAY,
  CASINO_FIVE,
  EMPTY_STRING,
  clubLevel,
} from '@/constants'
import {
  pipe,
  map,
  isNil,
  ifElse,
  always,
  head,
  isEmptyOrNil,
  formatNumberLocale,
} from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: PLATINCLUB,
  data: () => ({
    cardList: null,
    isPending: false,
  }),
  components: {
    Loader: () => import('@/components/Loader'),
    BalloonArrow: () => import('@/components/svg/BalloonArrow'),
    EmailIcon: () => import('@/components/svg/EmailIcon'),
    ClubCard: () => import('@/components/ClubCard'),
    ClubStatusIcon: () => import('@/components/ClubStatusIcon'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(PLATINCLUB)
    },
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    cardInfo() {
      const firstName = CasinoInfo.getFirstName(this.CASINO_INFO)
      const lastName = CasinoInfo.getLastName(this.CASINO_INFO)
      const cardId = CasinoInfo.getCardId(this.CASINO_INFO)
      const cardExpiryDate = CasinoInfo.getCardExpiryDate(this.CASINO_INFO)

      return {
        cardLevel: clubLevel[CasinoInfo.getCardLevel(this.CASINO_INFO)],
        userName: `${firstName} ${lastName}`,
        cardId: `ID: ${cardId}`,
        cardExpiration: `Since: ${cardExpiryDate}`,
      }
    },
    benefitTableData() {
      return ifElse(
        isNil,
        always(EMPTY_ARRAY),
        map(({ name, settings }) => ({ name, ...settings })),
      )(this.cardList)
    },
    benefitTableDictionary() {
      return ifElse(
        isNil,
        always(EMPTY_ARRAY),
        pipe(head, Object.keys),
      )(this.benefitTableData)
    },
    rendersBenefitTable() {
      return (
        !isEmptyOrNil(this.benefitTableData) &&
        !isEmptyOrNil(this.benefitTableData)
      )
    },
  },
  methods: {
    async getCardList() {
      try {
        this.isPending = true

        const { data, state } = await VipApi.getVipCards()
        state === ResponseState.OK && (this.cardList = data.items)

        this.isPending = false
      } catch (e) {
        console.log(e)
      }
    },
    getLeftThClass: (index) =>
      index === Digit.NOUGHT
        ? 'csn-platinclub-benefit-first-th'
        : 'csn-platinclub-benefit-left-th',
    getThClass: (rowIndex, columnIndex) =>
      rowIndex === Digit.NOUGHT
        ? `csn-platinclub-benefit-upper-th csn-platinclub-benefit-upper-th-${columnIndex}`
        : 'csn-platinclub-benefit-th',
    isStatusRaw: (rowIndex) => rowIndex === Digit.ONE,
    isBetAmountRaw: (rowIndex) => rowIndex === Digit.THREE,
    isAutomatedGiftRaw: (rowIndex) => rowIndex === Digit.FOUR,
    isRadioRaw: (rowIndex) =>
      rowIndex === Digit.FIVE ||
      rowIndex === Digit.SEVEN ||
      rowIndex === Digit.NINE,
    format: (number) =>
      formatNumberLocale({
        number,
        needsCoins: false,
        maximumFractionDigits: Digit.NOUGHT,
      }),
    getCardClass(index) {
      return [
        'csn-platinclub-card',
        `csn-platinclub-card-${index}`,
        this.isCasinoFive ? 'csn-platinclub-card-five' : EMPTY_STRING,
      ]
    },
  },
  mounted() {
    this.getCardList()
  },
}
</script>
